.background{
background: linear-gradient(159deg, rgba(5,11,37,1) 20%, rgba(0,18,48,1) 80%);
    display: flex;
    flex-direction: column;

}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
 justify-content: center;
 width:100%;
 margin:2% auto;
}
.wrap{
    display: flex;
    flex-direction: column;
    border-left: 2px solid whitesmoke;
    align-items: center;

    margin:0 auto;
    flex:1
}
.title{
    font-size: 3.5rem;
    font-family: "Assistant";
    color: rgb(255, 255, 255);
    text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.subTitle{
    font-size: 1.5rem;
    font-family: "Assistant";
    color: rgb(255, 255, 255);
    text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.description{
    font-size: 1.1rem;
    font-family: "AssistantR";
    color: rgb(255, 255, 255);
    text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}

@media only screen and (max-width: 350px) {


    .background{
    background: linear-gradient(159deg, rgba(5,11,37,1) 20%, rgba(0,18,48,1) 80%);
        display: flex;
        flex-direction: column;
    
    }
    .row{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     justify-content: center;
     width:100%;
     margin:2% auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid whitesmoke;
        border-left:none;
        align-items: center;
        margin:5% auto;
        flex:1
    }
    .title{
        font-size: 2rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .subTitle{
        font-size:1.2rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 1px 2px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1rem;
        font-family: "AssistantR";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    
}
@media only screen and (min-width: 350px) and (max-width: 450px) {


    .background{
    background: linear-gradient(159deg, rgba(5,11,37,1) 20%, rgba(0,18,48,1) 80%);
        display: flex;
        flex-direction: column;
    
    }
    .row{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     justify-content: center;
     width:100%;
     margin:2% auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid whitesmoke;
        border-left:none;
        align-items: center;
        margin:5% auto;
        flex:1
    }
    .title{
        font-size: 2.5rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .subTitle{
        font-size:1.4rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 1px 2px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.1rem;
        font-family: "AssistantR";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    
}
@media only screen and (min-width: 450px) and (max-width: 550px) {


    .background{
    background: linear-gradient(159deg, rgba(5,11,37,1) 20%, rgba(0,18,48,1) 80%);
        display: flex;
        flex-direction: column;
    
    }
    .row{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     justify-content: center;
     width:100%;
     margin:2% auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid whitesmoke;
        border-left:none;
        align-items: center;
        margin:5% auto;
        flex:1
    }
    .title{
        font-size: 3rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .subTitle{
        font-size:1.6rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 1px 2px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.1rem;
        font-family: "AssistantR";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    
}
@media only screen and (min-width: 550px) and (max-width: 850px) {


    .background{
    background: linear-gradient(159deg, rgba(5,11,37,1) 20%, rgba(0,18,48,1) 80%);
        display: flex;
        flex-direction: column;
    
    }
    .row{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
     justify-content: center;
     width:100%;
     margin:2% auto;
    }
    .wrap{
        display: flex;
        flex-direction: column;
        border-bottom: 2px solid whitesmoke;
        border-left:none;
        align-items: center;
        margin:5% auto;
        flex:1
    }
    .title{
        font-size: 3.2rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .subTitle{
        font-size:1.7rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 1px 2px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    .description{
        font-size: 1.1rem;
        font-family: "AssistantR";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:2% auto;
        direction: rtl;
    }
    
}
@media only screen and (min-width: 850px) and (max-width: 950px) {

    .title{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .subTitle{
        font-size: 1.5rem;
        font-family: "Assistant";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .description{
        font-size: 0.9rem;
        font-family: "AssistantR";
        color: rgb(255, 255, 255);
        text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    
}