.background {
    display: flex;
    align-items: center; 
    width: 100vw; 
    height: 100vh; 
    background-image: url("../images/רקע\ חדש.png");
    background-repeat: no-repeat;
    background-size: cover; 
    z-index: -1;
    margin:0;
    border-radius: 0  0  250px 250px ;
  }
  @media only screen and (max-width: 350px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:60vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  100px 100px ;
      }

  }
  @media only screen  and (min-width: 350px) and (max-width: 450px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:55vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  120px 120px ;
      }

  }
  @media only screen  and (min-width: 450px) and (max-width: 550px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:60vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  120px 120px ;
      }

  }
  @media only screen  and (min-width: 550px) and (max-width: 650px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:65vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  125px 125px ;
      }

  }
  @media only screen  and (min-width: 650px) and (max-width: 750px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:70vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  125px 125px ;
      }
  }
  @media only screen  and (min-width: 750px) and (max-width: 850px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:65vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  125px 125px ;
      }
  }
  @media only screen  and (min-width: 850px) and (max-width: 950px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:70vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  125px 125px ;
      }
  }
  @media only screen  and (min-width: 950px) and (max-width: 1050px) {

    .background {
        display: flex;
        align-items: center; 
        width: 100vw;
      height:75vh;
        background-image: url("../images/רקע\ חדש.png");
        background-repeat: no-repeat;
        background-size: cover; 
        z-index: -1;
        margin:0;
        border-radius: 0  0  125px 125px ;
      }
  }