.priceItem {
    display: flex;
    flex-direction: row;

    width: 100%;
    background: none;
    color: white;
    justify-content: center;
    align-items: center;
    padding:10px 20px
  }
  .wrap{
    display: flex;
    flex-direction: column;
    width:100%;
  }
  .line {
    width: 60%;
    margin: auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid rgb(255, 255, 255);
  }
  
  .line {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .lineFill {
    width: 100%;
    height: 1px;
    background-color: white;
  }
  
  .serviceName,
  .servicePrice {
    font-size: 1rem;
    font-family: "Assistant";
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
  }
  
  .priceList {
    display: flex;
    flex-direction: column;
    width:40%;
    padding:30px;
    margin: 3% auto;

    border-radius: 20px;
    justify-content: center;
    align-items: center;
      background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
    /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
    inset 0 0 5px rgba(176, 81, 4, 0.299),
    inset 0 0 5px rgba(146, 71, 6, 0.538); */
  }
  


  .header{
    font-size: 3.5rem;
    font-family: "Assistant";
    color: black;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.center{
    display: flex;
    width:50%;
    margin:0 auto;
    justify-content: center;
}
.logo{
    width:100%;
}

@media only screen and (max-width: 350px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 75vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 20px 0 20px 0;
        justify-content: center;
        align-items: center;
       background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size:0.8rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }

}
@media only screen  and (min-width: 350px) and (max-width: 450px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 80vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 20px 0 20px 0;
        justify-content: center;
        align-items: center;
          background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size: 0.8rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }

}
@media only screen  and (min-width: 450px) and (max-width: 550px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 70vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 20px 0 20px 0;
        justify-content: center;
        align-items: center;
          background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size: 1rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }

}

@media only screen  and (min-width: 550px) and (max-width: 650px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 65vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 25px 0 25px 0;
        justify-content: center;
        align-items: center;
          background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size: 1.1rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }

}
@media only screen  and (min-width: 650px) and (max-width: 750px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 60vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 25px 0 25px 0;
        justify-content: center;
        align-items: center;
          background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size: 1.1rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }
}
@media only screen  and (min-width: 750px) and (max-width: 850px) {

    .priceItem {
        display: flex;
        flex-direction: row;
    text-align: right;
        width: 100%;
        background: none;
        color: white;
        justify-content: center;
        align-items: center;
   
      }
      .wrap{
        display: flex;
        flex-direction: column;
        width:100%;
      }
      .line {
        width: 100%;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgb(255, 255, 255);
      }
      
      .line {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      
      .lineFill {
        width: 100%;
        height: 1px;
        background-color: white;
      }
      
   
      
      .priceList {
        display: flex;
        flex-direction: column;
        width: 55vw;
        padding:30px;
        margin: 3% auto;
     
        border-radius: 25px 0 25px 0;
        justify-content: center;
        align-items: center;
          background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(83,83,83,1) 10%, rgba(0,0,0,1) 20%, rgba(83,83,83,1) 30%, rgba(0,0,0,1) 40%, rgba(83,83,83,1) 50%, rgba(0,0,0,1) 60%, rgba(83,83,83,1) 70%, rgba(0,0,0,1) 80%, rgba(83,83,83,1) 90%, rgba(0,0,0,1) 100%);
        /* box-shadow: inset 0 0 10px  rgba(82, 63, 1, 0.138),
        inset 0 0 5px rgba(176, 81, 4, 0.299),
        inset 0 0 5px rgba(146, 71, 6, 0.538); */
      }
      
      .serviceName,
      .servicePrice {
        font-size: 1.1rem;
        font-family: "Assistant";
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2px 5px;
     
      }
      
    
      .header{
        font-size: 3.5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:1% auto;
        direction: rtl;
    }
    .center{
        display: flex;
        width:50%;
        margin:0 auto;
        justify-content: center;
    }
    .logo{
        width:100%;
    }
}