.a{
  text-decoration: none;
  color:#ffffff;
  font-size: 1.4rem;
margin:1%
}

.byMe{
 
    text-align:center;
width:100%;
    margin:1% auto;
    color: rgb(255, 255, 255);
    font-size: 1rem;
    font-family: "AssistantR";
}