.wrapper{
    display: flex;
    flex-direction: row;
    width:90%;
    margin:5% auto;
    height:auto;

}
.icon{
    display: flex;
   transform: rotateY(180deg);
    width:25%
}

.header{
    font-size: 5rem;
    font-family: "Assistant";
    color: black;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:3% auto;
    direction: rtl;
}
.row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    
}
.rowReverse{
    display: flex;
    flex-direction: row;
    width:100%;
    margin: 0 0  0 auto   ;

    align-items: center;
}
.right{
    display: flex;
    flex-direction: column;
 width:60%;
   
    margin-left: auto;
justify-content: space-evenly;

}

.title, .job{
    font-family: "Assistant";
    color: black;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
}
.title{
    font-size: 5rem;

}
.job{
    font-size: 3rem;
 margin-left:auto;
 
}
.description{
    font-size: 1.5rem;
    color: black;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: right;
    width:80%;
    margin-right: auto;
    direction: rtl;
    font-family: "AssistantR";
    
}

.left{
    display: flex;
    z-index: -1;
    flex: 1.5; /* Adjusted flex property */
}

.imageWrapper {
  
    display: flex;
    align-items: center;
    justify-content: center;
}
.bold{
    color:goldenrod;
    display: inline;
    z-index: -1;
}
.image{
    width: 400px; /* Adjusted width to fill the container */
    object-fit: cover;
    height: 400px;
    border-radius: 15px;
    box-shadow: 0 0 5px 1px #222;
}
.center{
    width:50% ;
    display: flex;
    justify-content: center;
    margin:2% auto;
}


@media only screen  and (max-width: 350px){
    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:100%;
        margin:5% auto;
        height:auto;
        justify-content: center;
        align-items: center;
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    

    .row{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin:0 auto;
        width:100%;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:2% auto;
    justify-content: center;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
       
       margin:0 auto;
    justify-content: center;

    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin:0 auto;
    }
    .title{
        font-size: 1.8rem;
        
    
    }
    .job{
        font-size: 1.2rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:95%;
        margin:0 auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1; /* Adjusted flex property */
        width:100%;
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 90%;
        object-fit: cover;
        height: 250px;
   
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    

}
@media only screen   and (min-width: 350px) and (max-width: 450px){


    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:100%;
        margin:5% auto;
        height:auto;
        justify-content: center;
        align-items: center;
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    

    .row{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin:0 auto;
        width:100%;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:2% auto;
    justify-content: center;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
       
       margin:0 auto;
    justify-content: center;

    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin:0 auto;
    
    }
    .title{
        font-size: 2rem;
        
    
    }
    .job{
        font-size: 1.4rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.1rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:95%;
        margin:0 auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1; /* Adjusted flex property */
        width:100%;
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 90%;
        object-fit: cover;
        height: 250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
}
@media only screen   and (min-width: 450px) and (max-width: 550px){


    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:100%;
        margin:5% auto;
        height:auto;
        justify-content: center;
        align-items: center;
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    

    .row{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin:0 auto;
        width:100%;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:2% auto;
    justify-content: center;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
       
       margin:0 auto;
    justify-content: center;

    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin:0 auto;
    
    }
    .title{
        font-size: 2.5rem;
        
    
    }
    .job{
        font-size: 1.7rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.2rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:95%;
        margin:0 auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1; /* Adjusted flex property */
        width:100%;
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 90%;
        object-fit: cover;
        height: 250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
  

}
@media only screen   and (min-width: 550px) and (max-width: 650px){
    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:100%;
        margin:5% auto;
        height:auto;
        justify-content: center;
        align-items: center;
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    

    .row{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin:0 auto;
        width:100%;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:2% auto;
    justify-content: center;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
       
       margin:0 auto;
    justify-content: center;

    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin:0 auto;
    
    }
    .title{
        font-size: 3rem;
        
    
    }
    .job{
        font-size: 2rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.2rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:95%;
        margin:0 auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1; /* Adjusted flex property */
        width:100%;
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 90%;
        object-fit: cover;
        height: 250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
}
@media only screen   and (min-width: 650px) and (max-width: 850px){

    .wrapper{
        display: flex;
        flex-direction: column-reverse;
        width:80%;
        margin:5% auto;
        height:auto;
        justify-content: center;
        align-items: center;
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    

    .row{
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items: center;
        margin:0 auto;
        width:100%;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin:2% auto;
    justify-content: center;
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:95%;
       
       margin:0 auto;
    justify-content: center;

    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        margin:0 auto;
    
    }
    .title{
        font-size: 3.5rem;
        
    
    }
    .job{
        font-size: 2.2rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.25rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:95%;
        margin:0 auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1; /* Adjusted flex property */
        width:100%;
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 70%;
        object-fit: cover;
        height: 250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }


}
@media only screen   and (min-width: 850px) and (max-width: 1050px){

    .wrapper{
        display: flex;
        flex-direction: row;
        width:90%;
        margin:5% auto;
        height:auto;
    
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    
    .header{
        font-size: 5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin: 0 0  0 auto   ;
    
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:60%;
       
        margin-left: auto;
    justify-content: space-evenly;
    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 2.7rem;
    
    }
    .job{
        font-size: 1.8rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.2rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:80%;
        margin-right: auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1.5; /* Adjusted flex property */
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 250px; /* Adjusted width to fill the container */
        object-fit: cover;
        height:250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
    
}
@media only screen   and (min-width: 1050px) and (max-width: 1250px){

 
    .wrapper{
        display: flex;
        flex-direction: row;
        width:90%;
        margin:5% auto;
        height:auto;
    
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    
    .header{
        font-size: 5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin: 0 0  0 auto   ;
    
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:60%;
       
        margin-left: auto;
    justify-content: space-evenly;
    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3rem;
    
    }
    .job{
        font-size: 2rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.25rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:80%;
        margin-right: auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1.5; /* Adjusted flex property */
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 250px; /* Adjusted width to fill the container */
        object-fit: cover;
        height:250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
}

@media only screen   and (min-width: 1250px) and (max-width: 1450px){

    .wrapper{
        display: flex;
        flex-direction: row;
        width:90%;
        margin:5% auto;
        height:auto;
    
    }
    .icon{
        display: flex;
       transform: rotateY(180deg);
        width:25%
    }
    
    .header{
        font-size: 5rem;
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: center;
        margin:3% auto;
        direction: rtl;
    }
    .row{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        
    }
    .rowReverse{
        display: flex;
        flex-direction: row;
        width:100%;
        margin: 0 0  0 auto   ;
    
        align-items: center;
    }
    .right{
        display: flex;
        flex-direction: column;
     width:60%;
       
        margin-left: auto;
    justify-content: space-evenly;
    
    }
    
    .title, .job{
        font-family: "Assistant";
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
    }
    .title{
        font-size: 3.4rem;
    
    }
    .job{
        font-size: 2.3rem;
     margin-left:auto;
     
    }
    .description{
        font-size: 1.4rem;
        color: black;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
        text-align: right;
        width:80%;
        margin-right: auto;
        direction: rtl;
        font-family: "AssistantR";
        
    }
    
    .left{
        display: flex;
        z-index: -1;
        flex: 1.5; /* Adjusted flex property */
    }
    
    .imageWrapper {
      
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .bold{
        color:goldenrod;
        display: inline;
        z-index: -1;
    }
    .image{
        width: 250px; /* Adjusted width to fill the container */
        object-fit: cover;
        height:250px;
        border-radius: 15px;
        box-shadow: 0 0 5px 1px #222;
    }
    .center{
        width:50% ;
        display: flex;
        justify-content: center;
        margin:2% auto;
    }
 
}