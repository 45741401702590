.background{
  background: linear-gradient(159deg, rgba(96,0,0,1) 30%, rgba(73,20,20,1) 50%, rgba(96,0,0,1) 70%);

}
.title{
    font-family: "Assistant";
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    font-size: 3.5rem;
padding-top:1rem;
    direction: rtl;
}

.description{
    font-family: "AssistantR";
    direction: rtl;
    color: white;
    font-size: 2rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
}

.sliderContainer {

    width: 80%;
    margin:2% auto;

    justify-content: center;
    align-items: center;
  }
  .slick-dots {
    color: var(--slick-dots-color, inherit);
  }
  
  .slick-dots li button {
    color: var(--slick-dots-color, inherit);
  }
  
  .center{
    display: flex;
    width:100%;
    justify-content: center;
  }
  .image{
    width:25%;
    height:25%;
  }
  .image1{
    width:100%;

    height:100%;
    object-fit: contain;
  }
 
.video{

    height:600px;
    width:400px;
    display: flex;
    margin:5% auto;
    object-fit: cover;
}

@media only screen and (max-width: 350px) {

    .sliderContainer {

        width: 80%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:50%;
        height:50%;
      
      }
      .background{
        background:#c7975c;
       
      
    }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size:30px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:0 auto;
       padding:10px;
        font-family: "Assistant";
    }
    .description{
        font-size: 18px;
        direction: rtl;
        width:95%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:400px;
        width:250px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 350px) and (max-width: 450px) {

    .sliderContainer {

        width: 70%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:50%;
        height:50%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 40px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:0 auto;
        font-family: "Assistant";
    }
    .description{
        font-size: 30px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:450px;
        width:300px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 450px) and (max-width: 550px) {

    .sliderContainer {

        width: 80%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:50%;
        height:50%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }
      .title{
        font-size: 50px;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:0 auto;
        font-family: "Assistant";
    }
    .description{
        font-size: 30px;
        direction: rtl;
        width:90%;
        color:white;
        justify-content: center;
        text-align: center;
        margin: 5% auto;
        font-family: "AssistantR";
    }
    .video{
    
        height:450px;
        width:350px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 550px) and (max-width: 650px) {

    .sliderContainer {

        width: 80%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:45%;
        height:45%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 650px) and (max-width: 750px) {
    .title{
        font-size: 45px;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:0 auto;
        font-family: "Assistant";
    }
    .sliderContainer {

        width:80%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:45%;
        height:45%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 750px) and (max-width: 850px) {
    .title{
        font-size: 50px;
        direction: rtl;
        width:75%;
        color:white;
        justify-content: center;
        text-align: center;
        margin:0 auto;
        font-family: "Assistant";
    }
    .sliderContainer {

        width: 70%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:35%;
        height:35%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:500px;
        width:400px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 850px) and (max-width: 950px) {
.title{
    padding:2% auto;
}
    .sliderContainer {

        width: 90%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:35%;
        height:35%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 950px) and (max-width: 1050px) {

    .sliderContainer {

        width: 90%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:30%;
        height:30%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}
@media only screen and (min-width: 1050px) and (max-width: 1250px) {

    .sliderContainer {

        width: 90%;
        margin: auto;
    
        justify-content: center;
        align-items: center;
      }
      .slick-dots {
        color: var(--slick-dots-color, inherit);
      }
      
      .slick-dots li button {
        color: var(--slick-dots-color, inherit);
      }
      
      .center{
        display: flex;
        width:100%;
        justify-content: center;
      }
      .image{
        width:30%;
        height:30%;
      
      }
      .image1{
        width:100%;
        height:100%;
        object-fit: cover;
      }

    .video{
    
        height:600px;
        width:450px;
        display: flex;
        margin:8% auto;
        object-fit: cover;
    }
}