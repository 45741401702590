.row{

width:100%;
    display: flex;
    flex-direction: row;
align-items: center;
    justify-content: center;
margin:2% auto;
}
.side{
    display: flex;
    align-content: center;
    justify-content: center;
width:50%;

}
.image{
 
    width:80%;
    object-fit: contain;
}

.title{
    font-size: 4rem;
    font-family: "Assistant";
    color: rgb(255, 255, 255);
    text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.description{
    font-size: 1.2rem;
    font-family: "AssistantR";
    color: rgb(255, 255, 255);
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
    text-align: center;
    margin:1% auto;
    direction: rtl;
}
.background{
    width:100%;
    background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(82,82,82,1) 50%, rgba(1,0,42,1) 80%);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media only screen  and (max-width: 350px){

    .row{
        width:100%;
            display: flex;
            flex-direction: column-reverse;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:100%;
        
        }
        .image{
     
            width:90%;
            object-fit: contain;
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
        }
        
        .title{
            font-size: 2rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 2px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
            display: flex;
            justify-content: center;
            align-items: center;
      
            flex-direction: column;
        }


}
@media only screen and (min-width: 350px) and (max-width: 450px){

    .row{
        width:100%;
            display: flex;
            flex-direction: column-reverse;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:100%;
        
        }
        .image{
     
            width:90%;
            object-fit: contain;
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
        }
        
        .title{
            font-size: 2rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 2px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
            display: flex;
            justify-content: center;
            align-items: center;
      
            flex-direction: column;
        }
}
@media only screen and (min-width: 450px) and (max-width: 550px){

    .row{
        width:100%;
            display: flex;
            flex-direction: column-reverse;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:100%;
        
        }
        .image{
     
            width:75%;
            object-fit: contain;
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
        }
        
        .title{
            font-size: 2.5rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 2px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1.2rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
            display: flex;
            justify-content: center;
            align-items: center;
      
            flex-direction: column;
        }
}
@media only screen and (min-width: 550px) and (max-width: 650px){

    .row{
        width:100%;
            display: flex;
            flex-direction: column-reverse;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:100%;
        
        }
        .image{
     
            width:70%;
            object-fit: contain;
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
        }
        
        .title{
            font-size: 3rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 2px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1.25rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
            display: flex;
            justify-content: center;
            align-items: center;
      
            flex-direction: column;
        }
}
@media only screen and (min-width: 650px) and (max-width: 850px){

    .row{
        width:100%;
            display: flex;
            flex-direction: column-reverse;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:100%;
        
        }
        .image{
     
            width:60%;
            object-fit: contain;
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
        }
        
        .title{
            font-size: 3.5rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 2px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1.4rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
            display: flex;
            justify-content: center;
            align-items: center;
      
            flex-direction: column;
        }
}
@media only screen and (min-width: 850px) and (max-width: 950px){
    .row{

        width:100%;
            display: flex;
            flex-direction: row;
        align-items: center;
            justify-content: center;
        margin:2% auto;
        }
        .side{
            display: flex;
            align-content: center;
            justify-content: center;
        width:40%;
        
        }
        .image{
            border-radius:0 30px 0 30px ;
            box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.737); ;
            width:90%;
            object-fit: contain;
        }
        
        .title{
            font-size: 4rem;
            font-family: "Assistant";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(255, 252, 252, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .description{
            font-size: 1.2rem;
            font-family: "AssistantR";
            color: rgb(255, 255, 255);
            text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.414);
            text-align: center;
            margin:1% auto;
            direction: rtl;
        }
        .background{
            width:100%;
            background: linear-gradient(159deg, rgba(1,0,42,1) 20%, rgba(120,118,118,1) 50%, rgba(1,0,42,1) 80%);
        
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
}