.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family:"AssistantR";
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    background-color: rgba(0,5,40,1);

   
    border-color: rgba(255, 234, 204, 0.333);
    border-radius: 15px;
    padding: 16px 24px 16px 28px;
    transform: translate(0px, 0px) rotate(0deg);
    transition:  all ease 0.2s;
    margin:2% ;
 
    box-shadow: 0 0px 8px 1px #ffffff, 4px 2px 16px 0px rgba(5, 39, 81, 0.48);
   }
   .buttonL {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: Calibri;
    font-size: 18px;
    font-weight: 500;
    text-transform: uppercase;
    color: #ffffff;
    background-color: rgba(0,5,40,1);

   
    border-color: rgba(255, 234, 204, 0.333);
    border-radius: 15px;
    padding: 16px 24px 16px 28px;
    transform: translate(0px, 0px) rotate(0deg);
    transition:  all ease 0.2s;
    margin:2% ;
 
    box-shadow: 0 0px 8px 1px #ffffff, 4px 2px 16px 0px rgba(5, 39, 81, 0.48);
   }
   
   .button:hover{
    color: rgb(255, 255, 255);
    background-color: rgba(0,5,40,1);
    box-shadow: -2px -1px 8px 0px #ffffff, 2px 1px 8px 0px rgba(3, 44, 93, 0.48);
   }
   
   .button:active {
    box-shadow: none;
   }

   @media only screen and (max-width: 450px) {


    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-family:"AssistantR";
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
        color: #ffffff;
        background-color:rgba(0,5,40,1);
    
       
        border-color: rgba(255, 234, 204, 0.333);
        border-radius: 15px;
        padding: 12px 20px 12px 20px;
        transform: translate(0px, 0px) rotate(0deg);
        transition:  all ease 0.2s;
        margin:2% ;
     
        box-shadow: 0 0px 3px 1px #ffffff, 4px 2px 4px 0px rgba(5, 39, 81, 0.48);
       }

   }